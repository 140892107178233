import QrCodeStyling from "qr-code-styling";
import "./index.css";
import "./app.css";
import NodesBinder from "./js/nodes-binder";
import { getSrcFromFile } from "./js/tools";
import defaultImage from "./assets/CHLA_Butterfly_Black_Outline.svg";
import pathtoimage from "./assets/qrimage.png";
import '@okta/okta-auth-js/polyfill';
import { OktaAuth } from '@okta/okta-auth-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import PoppinsRegularWoff from "./assets/font/Poppins-Regular.woff";

const appInsights = new ApplicationInsights({ config: {
    instrumentationKey: '40308a4c-7fb5-41c1-aa68-35efcc8d52bf'
    /* ...Other Configuration Options... */
  } });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); 
function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// var config = {
//     issuer: 'https://dev-775951.okta.com/oauth2/aus3u3lvavkfkkma8357',
//     clientId: '0oa68opcffwMpzh1O357',
//     redirectUri: window.location.origin ,
//   };
var config = {
    issuer: 'https://oktalogin.chla.org/oauth2/aus77svjdiCvaf9cC2p7',
    clientId: '0oaeic6c37kvANUup2p7',
    
    redirectUri: window.location.origin+"/",
  scopes: ['email','profile','openid','qrcodegenerator:admin','qrcodegenerator:pfe'],
//   pkce: true,
  disableHttpsCheck: false,
    
  };
var authClient = new OktaAuth(config);

if (authClient.isLoginRedirect()) {
    // Parse token from redirect url
    authClient.token.parseFromUrl()
      .then(data => {
        const { idToken } = data.tokens;
        
        // Store parsed token in Token Manager
        authClient.tokenManager.add('idToken', data.tokens.idToken);
        authClient.tokenManager.add('accessToken', data.tokens.accessToken);
        var json = parseJwt(authClient.getAccessToken())
        //console.log(json);
       
        //display option for Admin group...
        if(json.QRCodeAdminClaim || json.QRCodeAdminClaim && json.QRCodePFEClaim){
            var el = document.getElementsByClassName("accordion");
            Array.from(el).forEach((element) => {
            element.style.display = "block";
        
            });
        }

        //display option for PLE group...
        if(json.QRCodePFEClaim && !(json.QRCodeAdminClaim)){
            var accordions = document.getElementsByClassName("accordion");
            Array.from(accordions).forEach((accordion) => {
                accordion.style.display = "none";
            });

            if (accordions.length > 0) {
                accordions[2].style.display = "block";
            }
        }
        
        window.location.href = window.location.origin+"/app.html"
      });
  } else {
    // Attempt to retrieve ID Token from Token Manager
    if(authClient.isAuthenticated()){
        
        authClient.tokenManager.get('accessToken')
      .then(idToken => {
        
        if (idToken) {
          //console.log(idToken);
          if(!window.location.href.includes("app.html"))
          window.location.href = window.location.origin+"/app.html"
          var json = parseJwt(authClient.getAccessToken())
          console.log(`tokenObject`,json);

           //display option for Admin group...
            if(json.QRCodeAdminClaim || json.QRCodeAdminClaim && json.QRCodePFEClaim){
                console.log(`currentClaim =>`,json);
                var el = document.getElementsByClassName("accordion");
                Array.from(el).forEach((element) => {
                element.style.display = "block";
            
                });   
            }

            //display option for PLE group...
            if(json.QRCodePFEClaim && !(json.QRCodeAdminClaim)){
                //console.log(`currentClaim =>`,json);
                var accordions = document.getElementsByClassName("accordion");
                Array.from(accordions).forEach((accordion) => {
                    accordion.style.display = "none";
                });

                if (accordions.length > 0) {
                    accordions[2].style.display = "block";
                }
            }
        } else {
          // You're not logged in, you need a sessionToken
          authClient.token.getWithRedirect({
            responseType: 'id_token'
          });
        }
      })
    }else{
        
        authClient.signInWithRedirect()
    }
    
  }
async function handleRedirect(){
    await authClient.handleLoginRedirect();
}
var json = parseJwt(authClient.getAccessToken())
authClient.getUser().then(user=>{
    var tag = document.getElementById("username")
    tag.innerText = user.name
    // tag.innerHTML = <h4 id="logout" class="header-username">Logout</h4>
}).catch(err=>{
    // console.log("here",err)
    authClient.signInWithRedirect()
})

const style = document.createElement("style");

style.innerText = `@font-face {font-family: 'Poppins-Regular';src: local('Poppins-Regular'), url('${PoppinsRegularWoff}') format('woff');font-display: block;}`;

document.head.appendChild(style);

if(json.QRCodeAdminClaim || json.QRCodeAdminClaim && json.QRCodePFEClaim){
    console.log(`jsonData =>`,json);
    var el = document.getElementsByClassName("accordion");
    Array.from(el).forEach((element) => {
    element.style.display = "block";    
    });
    
    authClient.getUser().then(user=>{
        var tag = document.getElementById("username")
        tag.innerText = user.name + " (Admin)"
        // tag.innerHTML = <h4 id="logout" class="header-username">Logout</h4>
    }).catch(err=>{
        // console.log("here",err.response)
        authClient.signInWithRedirect()
    })

}
if(json.QRCodePFEClaim && !(json.QRCodeAdminClaim)){
    
    authClient.getUser().then(user=>{
        var tag = document.getElementById("username")
        tag.innerText = user.name + " (PFE)"
        // tag.innerHTML = <h4 id="logout" class="header-username">Logout</h4>
    }).catch(err=>{
        // console.log("here",err.response)
        authClient.signInWithRedirect()
    })

}

const form = document.getElementById("form");
const descriptionContainer = document.getElementById("qr-description");

var elem = document.createElement("img");
elem.setAttribute("src", pathtoimage);
elem.setAttribute("alt", "QR Image");

var sitedata = "";
var name = "";
var wl1 = "";
var wn1 = "";
var wl2 = "";
var wn2 = "";
var phoneno = "";
var street = "";
var city = "";
var zip = "";
var addrstate = "";
var country = "";
var email = "";
var org = "";
var title = "";
var webl1 = "";
var website1 = "";
var webl2 = "";
var website2 = "";

var switchdata = false;

var qr_data = null;



const nodesBinder = new NodesBinder({
    root: form
});

const initState = nodesBinder.getState();

delete initState.dotsOptions.gradient;

const qrCode = new QrCodeStyling({
    ...initState,
    image: defaultImage,
});

function convertSVG(image){
    fetch(image)
    .then(response => response.text())
    .then(text => {

        const parser = new DOMParser();
        const parsed = parser.parseFromString(text, 'text/html');
        const svg = parsed.querySelector('svg');

        if (image !== null && svg !== null) {
            var svg_xml = (new XMLSerializer()).serializeToString(svg);
            var imageBase64 = 'data:image/svg+xml;base64,' + btoa(svg_xml)
            qrCode.update({
                            ...initState,
                            image: imageBase64
                        });

            document.getElementById("qr-download").style.cursor = "not-allowed";
            document.getElementById("qr-code-generated").appendChild(elem);
        }
    });
}

convertSVG(defaultImage)

function updateDescriptionContainerBackground(backgroundColor, qrColor) {
    let leftColor, rightColor;

    if (getPerceptualBrightness(backgroundColor) > getPerceptualBrightness(qrColor)) {
        leftColor = qrColor;
        rightColor = backgroundColor;
    } else {
        leftColor = backgroundColor;
        rightColor = qrColor;
    }

    descriptionContainer.style["background-image"] = `linear-gradient(90deg, #000 0%, ${leftColor} 50%, ${rightColor} 100%)`;
}

function getPerceptualBrightness(color) {

    const r = parseInt(color.substring(1,3),16);
    const g = parseInt(color.substring(3,5),16);
    const b = parseInt(color.substring(5,6),16);

    return r + g + b;
}

updateDescriptionContainerBackground(initState.dotsOptions.color, initState.backgroundOptions.color);


function updateInputData(inputType,value){
    
    if(inputType !== "switchdata"){
        if(inputType === "cornersSquareOptions.type" || inputType ==="cornersDotOptions.type"){
            value =null;
        }
        updateDisabledState(value);
    }
    
};


window.addEventListener('load', function () {
    updateDisabledState("");
});

function isBase64SVG(base64Data) {
    if (typeof base64Data !== 'string') {
        return false;
    }

    const regex = /^data:image\/svg\+xml;base64,/;

    return regex.test(base64Data);
}

function base64ToSvg(base64Data) {

    if(isBase64SVG(base64Data)){
        const svgData = atob(base64Data.split(',')[1]);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgData, 'image/svg+xml');
        const svgElement = xmlDoc.documentElement;
        const svgString = svgElement.outerHTML;
        var modifiedSvgString = modifySvgDimensions(svgString,100,100);
        const modifiedBase64Data = 'data:image/svg+xml;base64,' + btoa(modifiedSvgString);
        return modifiedBase64Data;
    }
    else{
        return base64Data;
    }
    
}

function modifySvgDimensions(svgString, width, height) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgString, 'image/svg+xml');
    const svgElement = xmlDoc.documentElement;
    const hasWidth = svgElement.hasAttribute('width');
    const hasHeight = svgElement.hasAttribute('height');
    if (!hasWidth) {
        svgElement.setAttribute('width', width);
    }
    if (!hasHeight) {
        svgElement.setAttribute('height', height);
    }
    const modifiedSvgString = new XMLSerializer().serializeToString(xmlDoc);

    return modifiedSvgString;
}

function updateDisabledState(inputData) {
    var inputFile = document.getElementById("form-image-file");
    var cancelButton = document.getElementById("button-cancel");
    var dotColorSingle = document.getElementById("form-dots-color-type-single");
    var dotColorGradient = document.getElementById("form-dots-color-type-gradient");
    var cSquareSingle = document.getElementById("form-corners-square-color-type-single");
    var cSquareGradient = document.getElementById("form-corners-square-color-type-gradient");
    var cDotSingle = document.getElementById("form-corners-dot-color-type-single");
    var cDotGradient = document.getElementById("form-corners-dot-color-type-gradient");
    var backgroundColorSingle = document.getElementById("form-background-color-type-single");
    var backgroundColorGradient = document.getElementById("form-background-color-type-gradient");
    var clearCornerbutton = document.getElementById("button-clear-corners-square-color");
    var clearCornerbuttonDot = document.getElementById("button-clear-corners-dot-color");
    var dotOptiontype = document.getElementById("form-dots-type");
    var cornerSquaretype = document.getElementById("form-corners-square-type");
    var cornerDotType = document.getElementById("form-corners-dot-type");
    var hideBackgroundDots = document.getElementById("form-hide-background-dots");
    var formWidth = document.getElementById("form-width");
    var formHeight = document.getElementById("form-height");
    var formMargin = document.getElementById("form-margin");
    var dotColor = document.getElementById("form-dots-color");
    var cornerSquareColor = document.getElementById("form-corners-square-color");
    var cornerDotColor = document.getElementById("form-corners-dot-color");
    var backgroundColor = document.getElementById("form-background-color");
    var imageSize = document.getElementById("form-image-size");
    var imageMargin = document.getElementById("form-image-margin");

    // Update disabled state based on inputData
    inputFile.disabled = inputData === "";
    cancelButton.disabled = inputData === "";
    dotColorSingle.disabled = inputData === "";
    dotColorGradient.disabled = inputData === "";
    cSquareSingle.disabled = inputData === "";
    cSquareGradient.disabled = inputData === "";
    cDotSingle.disabled = inputData === "";
    cDotGradient.disabled = inputData === "";
    backgroundColorSingle.disabled = inputData === "";
    backgroundColorGradient.disabled = inputData === "";
    clearCornerbutton.disabled = inputData === "";
    clearCornerbuttonDot.disabled = inputData === "";
    dotOptiontype.disabled = inputData === "";
    cornerSquaretype.disabled = inputData === "";
    cornerDotType.disabled = inputData === "";
    hideBackgroundDots.disabled = inputData === "";
    formWidth.disabled = inputData === "";
    formHeight.disabled = inputData === "";
    formMargin.disabled = inputData === "";
    dotColor.disabled = inputData === "";
    cornerSquareColor.disabled = inputData === "";
    cornerDotColor.disabled = inputData === "";
    backgroundColor.disabled = inputData === "";
    imageSize.disabled = inputData === "";
    imageMargin.disabled = inputData === "";


}

nodesBinder.onStateUpdate(({ field, data }) => {
    const { image, imageUrl, dotsOptionsHelper, cornersSquareOptionsHelper, cornersDotOptionsHelper, backgroundOptionsHelper, ...state } = nodesBinder.getState();
   // convertSVG(image)
   updateInputData(field,data);
    
    if(field == "switchdata"){
        if(switchdata == false)
            switchdata = true
        else
            switchdata = false
    }

    if(field == "sitedata"){
        sitedata = data;
    }

    if(field == "fullname"){
        name = data;
    }

    if(field == "wl1"){
        wl1 = data;
    }

    if(field == "wn1"){
        wn1 = data;
    }

    if(field == "wl2"){
        wl2 = data;
    }

    if(field == "wn2"){
        wn2 = data;
    }

    if(field == "phoneno"){
        phoneno = data;
    }

    if(field == "street"){
        street = data;
    }

    if(field == "city"){
        city = data;
    }

    if(field == "zip"){
        zip = data;
    }

    if(field == "state"){
        addrstate = data;
    }

    if(field == "country"){
        country = data;
    }

    if(field == "email"){
        email = data;
    }

    if(field == "org"){
        org = data;
    }

    if(field == "title"){
        title = data;
    }

    if(field == "webl1"){
        webl1 = data;
    }

    if(field == "website1"){
        website1 = data;
    }

    if(field == "webl2"){
        webl2 = data;
    }

    if(field == "website2"){
        website2 = data;
    }

    if((sitedata === "") && switchdata==false){
        qr_data = null
    } else if((sitedata !== "") && switchdata==false) {

        let parserURL = sitedata

        if((sitedata.includes("https://") || (sitedata.includes("http://"))))
        {
            parserURL = sitedata.substring(sitedata.search("https://")+8, sitedata.length) || sitedata.substring(sitedata.search("http://")+7, sitedata.length)
        }

        qr_data = `https://qrcode.chla.org/redirect.html?url=${parserURL}`
    }

    if((name==="" && phoneno==="" && wl1==="" && wn1==="" && wl2==="" && wn2==="" && email === "" && org==="" && title === "" && webl1==="" && website1==="" && webl2==="" && website2==="" && street==="" && city ==="" && zip ==="" && addrstate ==="" && country ==="") && switchdata==true){
        qr_data = null
    } else if((name !== "" || wn1 !== "" || wn2 !== "" || phoneno !== "" || email !== "" || org !== "" || title !== "" || website1 !== "" || website2 !== "" || street !== "" || city !== "" || zip !== "" || addrstate !== "" || country !== "") && switchdata==true) {
        qr_data = `BEGIN:VCARD\nN:${name}\nitem1.TEL;TYPE=WORK,VOICE:${wn1}\nitem1.X-ABLabel:${wl1}\nitem2.TEL;TYPE=WORK,VOICE:${wn2}\nitem2.X-ABLabel:${wl2}\nTEL;TYPE=HOME,VOICE:${phoneno}\nADR;TYPE=WORK,PREF:;;${street};${city};${addrstate};${zip};${country}\nEMAIL:${email}\nORG:${org}\nTITLE:${title}\nitem3.URL:${website1}\nitem3.X-ABLabel:${webl1}\nitem4.URL:${website2}\nitem4.X-ABLabel:${webl2}\nVERSION:3.0\nEND:VCARD`
    }
    

    qrCode.update({
        data:qr_data
    });


    updateDescriptionContainerBackground(state.dotsOptions.color, state.backgroundOptions.color);

    if (field === "image") {
        console.log("data",data)
        if (data && data[0]) {
            getSrcFromFile(data[0], result => {
                var newImg = base64ToSvg(result);
                qrCode.update({
                    image: newImg
                });
            });
        } 
        else if(data === undefined){
            console.log(`image is undefined`);
            qrCode.update({
                image: defaultImage
            });
        }
         else {
            console.log(`image is null`);
            qrCode.update({
                image: null
            });
        }
    }
    
    if (field === "dotsOptionsHelper.colorType.gradient" && data) {
        const showElements = document.getElementsByClassName("dotsOptionsHelper.colorType.gradient")
        const hideElements =document.getElementsByClassName("dotsOptionsHelper.colorType.single")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            dotsOptions: {
                color: undefined,
                gradient: {
                    type: dotsOptionsHelper.gradient.linear ? "linear" : "radial",
                    rotation: dotsOptionsHelper.gradient.rotation / 180 * Math.PI,
                    colorStops: [{
                        offset: 0,
                        color: dotsOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: dotsOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "dotsOptionsHelper.colorType.single" && data) {
        const showElements = document.getElementsByClassName("dotsOptionsHelper.colorType.single")
        const hideElements =document.getElementsByClassName("dotsOptionsHelper.colorType.gradient")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            dotsOptions: {
                color: state.dotsOptions.color,
                gradient: null
            }
        });
        return;
    }

    if (field === "dotsOptionsHelper.gradient.linear" && data) {
        qrCode.update({
            dotsOptions: {
                gradient: {
                    type: "linear"
                }
            }
        });
        return;
    }

    if (field === "dotsOptionsHelper.gradient.radial" && data) {
        qrCode.update({
            dotsOptions: {
                gradient: {
                    type: "radial"
                }
            }
        });
        return;
    }

    if (field === "dotsOptionsHelper.gradient.color1") {
        qrCode.update({
            dotsOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: data
                    }, {
                        offset: 1,
                        color: dotsOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "dotsOptionsHelper.gradient.color2") {
        qrCode.update({
            dotsOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: dotsOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: data
                    }]
                }
            }
        });
        return;
    }

    if (field === "dotsOptionsHelper.gradient.rotation") {
        qrCode.update({
            dotsOptions: {
                gradient: {
                    rotation: dotsOptionsHelper.gradient.rotation / 180 * Math.PI,
                }
            }
        });
        return;
    }


    if (field === "cornersSquareOptionsHelper.colorType.gradient" && data) {
        const showElements = document.getElementsByClassName("cornersSquareOptionsHelper.colorType.gradient")
        const hideElements =document.getElementsByClassName("cornersSquareOptionsHelper.colorType.single")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            cornersSquareOptions: {
                color: undefined,
                gradient: {
                    type: cornersSquareOptionsHelper.gradient.linear ? "linear" : "radial",
                    rotation: cornersSquareOptionsHelper.gradient.rotation / 180 * Math.PI,
                    colorStops: [{
                        offset: 0,
                        color: cornersSquareOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: cornersSquareOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "cornersSquareOptionsHelper.colorType.single" && data) {
        const showElements = document.getElementsByClassName("cornersSquareOptionsHelper.colorType.single")
        const hideElements =document.getElementsByClassName("cornersSquareOptionsHelper.colorType.gradient")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            cornersSquareOptions: {
                color: state.cornersSquareOptions.color,
                gradient: null
            }
        });
        return;
    }

    if (field === "cornersSquareOptionsHelper.gradient.linear" && data) {
        qrCode.update({
            cornersSquareOptions: {
                gradient: {
                    type: "linear"
                }
            }
        });
        return;
    }

    if (field === "cornersSquareOptionsHelper.gradient.radial" && data) {
        qrCode.update({
            cornersSquareOptions: {
                gradient: {
                    type: "radial"
                }
            }
        });
        return;
    }

    if (field === "cornersSquareOptionsHelper.gradient.color1") {
        qrCode.update({
            cornersSquareOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: data
                    }, {
                        offset: 1,
                        color: cornersSquareOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "cornersSquareOptionsHelper.gradient.color2") {
        qrCode.update({
            cornersSquareOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: cornersSquareOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: data
                    }]
                }
            }
        });
        return;
    }

    if (field === "cornersSquareOptionsHelper.gradient.rotation") {
        qrCode.update({
            cornersSquareOptions: {
                gradient: {
                    rotation: cornersSquareOptionsHelper.gradient.rotation / 180 * Math.PI,
                }
            }
        });
        return;
    }

    if (field === "cornersDotOptionsHelper.colorType.gradient" && data) {
        const showElements = document.getElementsByClassName("cornersDotOptionsHelper.colorType.gradient")
        const hideElements =document.getElementsByClassName("cornersDotOptionsHelper.colorType.single")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            cornersDotOptions: {
                color: undefined,
                gradient: {
                    type: cornersDotOptionsHelper.gradient.linear ? "linear" : "radial",
                    rotation: cornersDotOptionsHelper.gradient.rotation / 180 * Math.PI,
                    colorStops: [{
                        offset: 0,
                        color: cornersDotOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: cornersDotOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "cornersDotOptionsHelper.colorType.single" && data) {
        const showElements = document.getElementsByClassName("cornersDotOptionsHelper.colorType.single")
        const hideElements =document.getElementsByClassName("cornersDotOptionsHelper.colorType.gradient")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            cornersDotOptions: {
                color: state.cornersDotOptions.color,
                gradient: null
            }
        });
        return;
    }

    if (field === "cornersDotOptionsHelper.gradient.linear" && data) {
        qrCode.update({
            cornersDotOptions: {
                gradient: {
                    type: "linear"
                }
            }
        });
        return;
    }

    if (field === "cornersDotOptionsHelper.gradient.radial" && data) {
        qrCode.update({
            cornersDotOptions: {
                gradient: {
                    type: "radial"
                }
            }
        });
        return;
    }

    if (field === "cornersDotOptionsHelper.gradient.color1") {
        qrCode.update({
            cornersDotOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: data
                    }, {
                        offset: 1,
                        color: cornersDotOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "cornersDotOptionsHelper.gradient.color2") {
        qrCode.update({
            cornersDotOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: cornersDotOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: data
                    }]
                }
            }
        });
        return;
    }

    if (field === "cornersDotOptionsHelper.gradient.rotation") {
        qrCode.update({
            cornersDotOptions: {
                gradient: {
                    rotation: cornersDotOptionsHelper.gradient.rotation / 180 * Math.PI,
                }
            }
        });
        return;
    }



    if (field === "backgroundOptionsHelper.colorType.gradient" && data) {
        const showElements = document.getElementsByClassName("backgroundOptionsHelper.colorType.gradient")
        const hideElements =document.getElementsByClassName("backgroundOptionsHelper.colorType.single")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            backgroundOptions: {
                color: undefined,
                gradient: {
                    type: backgroundOptionsHelper.gradient.linear ? "linear" : "radial",
                    rotation: backgroundOptionsHelper.gradient.rotation / 180 * Math.PI,
                    colorStops: [{
                        offset: 0,
                        color: backgroundOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: backgroundOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "backgroundOptionsHelper.colorType.single" && data) {
        const showElements = document.getElementsByClassName("backgroundOptionsHelper.colorType.single")
        const hideElements =document.getElementsByClassName("backgroundOptionsHelper.colorType.gradient")

        Array.from(showElements).forEach((element) => {
            element.style.visibility = "visible";
            element.style.height = "auto";
        });

        Array.from(hideElements).forEach((element) => {
            element.style.visibility = "hidden";
            element.style.height = "0";
        });

        qrCode.update({
            backgroundOptions: {
                color: state.backgroundOptions.color,
                gradient: null
            }
        });
        return;
    }

    if (field === "backgroundOptionsHelper.gradient.linear" && data) {
        qrCode.update({
            backgroundOptions: {
                gradient: {
                    type: "linear"
                }
            }
        });
        return;
    }

    if (field === "backgroundOptionsHelper.gradient.radial" && data) {
        qrCode.update({
            backgroundOptions: {
                gradient: {
                    type: "radial"
                }
            }
        });
        return;
    }

    if (field === "backgroundOptionsHelper.gradient.color1") {
        qrCode.update({
            backgroundOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: data
                    }, {
                        offset: 1,
                        color: backgroundOptionsHelper.gradient.color2
                    }]
                }
            }
        });
        return;
    }

    if (field === "backgroundOptionsHelper.gradient.color2") {
        qrCode.update({
            backgroundOptions: {
                gradient: {
                    colorStops: [{
                        offset: 0,
                        color: backgroundOptionsHelper.gradient.color1
                    }, {
                        offset: 1,
                        color: data
                    }]
                }
            }
        });
        return;
    }

    if (field === "backgroundOptionsHelper.gradient.rotation") {
        qrCode.update({
            backgroundOptions: {
                gradient: {
                    rotation: backgroundOptionsHelper.gradient.rotation / 180 * Math.PI,
                }
            }
        });
        return;
    }


    qrCode.update(state);

    if(qr_data == null){
        document.getElementById("qr-download").style.cursor = "not-allowed";
        document.getElementById("qr-code-generated").appendChild(elem);
    } else {
        document.getElementById("qr-download").style.cursor = "pointer";
    }
});


const qrContainer = document.getElementById("qr-code-generated");

qrContainer.appendChild(elem);

qrCode.append(qrContainer);

document.getElementById("button-cancel").onclick = () => {
    var buttonElement = document.getElementById("button-cancel");
    if(buttonElement.textContent === 'Remove Image'){
        buttonElement.textContent = 'Add Default Image';
        nodesBinder.setState({ image: new DataTransfer().files });
    }
    else if(buttonElement.textContent === 'Add Default Image'){
        buttonElement.textContent = 'Remove Image';
        nodesBinder.setState({ image: undefined });
    }
    
};

document.getElementById("button-clear-corners-square-color").onclick = () => {
    const state = nodesBinder.getState();
    nodesBinder.setState({ cornersSquareOptions: {
        color: state.dotsOptions.color
    }});
};

document.getElementById("button-clear-corners-dot-color").onclick = () => {
    const state = nodesBinder.getState();
    nodesBinder.setState({ cornersDotOptions: {
        color: state.dotsOptions.color
    }});
};
document.getElementById("logout").onclick =()=>{
    authClient.signOut()
}
document.getElementById("qr-download").onclick = () => {
    const extension = document.getElementById("qr-extension").value;
    if(qr_data != null){
        var json = parseJwt(authClient.getAccessToken())
        var email = json.sub
        
        var isAdmin = json.QRCodeAdminClaim ? true : false
        
        appInsights.trackEvent({
            name: 'Qr_Download',
            properties: { // accepts any type
                userData:{
                    userEmail: email,
                    url: qr_data,
                    version: isAdmin
                }
              
            }
          });
          qrCode.download({ extension, name: "chla-qr-code" });
    }
};


if(qr_data == null){
    document.getElementById("qr-download").style.cursor = "not-allowed";
    document.getElementById("qr-code-generated").appendChild(elem);
}

//Download options
document.getElementById("export-options").addEventListener("click", function() {
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(qrCode._options))}`;
    this.setAttribute("href",dataStr);
    this.setAttribute("download", "options.json");
});

//Accordion
let acc = document.getElementsByClassName("accordion");

for (let i = 0; i < acc.length; i++) {
    if (acc[i].classList.contains("accordion--open")) {
        continue;
    }

    acc[i].addEventListener("click", function() {
        this.classList.toggle("active");

        const panel = this.nextElementSibling;
        if (panel.style.display === "grid") {
            panel.style.display = "none";
        } else {
            panel.style.display = "grid";
        }
    });
}
